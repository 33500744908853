<template>
    <div>

        <div class="main">
            <div class="title">
                接单管理
            </div>

            <div class="status">
                 <div class="text_left">已支付</div>
                 <div></div>
                 <div>已完成</div>
            </div>


            <div class="content">

                <div class="vertical">

                    <div class="order" v-for="(item,index) in pay_list" :key="index">
                        <div class="order-desc">
                            <img src="../assets/order.png" mode="">
                            <b>订单号：</b>{{item.order_id}}
                        </div>
                        <div class="order-number">
                            <b>下单时间</b>：{{item.payment_time}}
                        </div>
                        <div class="order-number">
                            <b>服务内容</b>：资讯订单
                        </div>
                        <div class="order-status">
                            <div>
                                订单状态：{{item.statusname}}
                            </div>
                            <div class="cancel_order" @click="confirm(item)">
                                完成咨询
                            </div>
                        </div>
                    </div>

                </div>
                <div class="vertical">

                    <div class="order" v-for="(item,index) in complete_list" :key="index">
                        <div class="order-desc">
                            <img src="../assets/order.png" mode="">
                            <b>订单号：</b>{{item.order_id}}
                        </div>
                        <div class="order-number">
                            <b>下单时间</b>：{{item.payment_time}}
                        </div>
                        <div class="order-number">
                            <b>服务内容</b>：资讯订单
                        </div>
                        <div class="order-status">
                            <div>
                                订单状态：{{item.statusname}}
                            </div>
                        </div>
                    </div>

                </div>

            </div>





        </div>

        

    </div>
</template>
<script>
export default {
    data(){
        return{
            searchData:{
					page:'',
					limit:''
            },
            pay_list:[],
            complete_list:[]
        }
    },
    created(){
        this.loadData()
    },
    methods:{
        loadData(){
				this.$api.userpost('user.getorderBoard',this.searchData,(res)=>{
					if(res.data.status){
                        let temporary_list = res.data.data
						for(let i = 0 ; i < temporary_list.length ; i ++){
                            if(temporary_list[i].status == 1){
                                this.pay_list.push(temporary_list[i])
                            }else if(temporary_list[i].status == 2){
                                this.complete_list.push(temporary_list[i])
                            }
                        }
					}else{
						this.$common.toast(res.data.msg)
					}
				})
        },
        confirm(item){
            this.$api.post('order.confirm',{token:'e3b47817e1d212a419a234f5a8c65c21',order_ids:item.order_id},(res)=>{
                if(res.data.status){
                    this.loadData()
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .main{
        padding: 30px;
        margin: 40px auto;
        width: 1140px;
        background: #F1F4F8;
        opacity: 1;
        border-radius: 30px;
    }
    .title{
       text-align: left;
       font-size: 26px; 
       color: #144591;
    }

    .status{
        margin-top: 30px;
        width: 1140px;
        height: 50px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #666;
        div:nth-child(1){
            width: 347px;
           padding-left: 30px;
        }
        div:nth-child(2){
            width: 1px;
            height: 38px;
            background: #D8D8D8;
        }
         div:nth-child(3){
           padding-left: 30px;
        }
     
    }

    .content{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        width: 750px;
        .vertical{
            width: 360px;
        }
    }

      .order{
		border: 1px #fff solid;
		width: 360px;
		height: 150px;
		background-color: #FFF;
		border-radius: 10px;
        margin-bottom: 30px;
	}
	
	.order-desc{
		margin: 15px 0 0 10px;
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #666;
	}
	.order-desc img{
		height: 17px;
		width: 14px;
		margin-right:11px;
	}
	.order-number{
		width: 100%;
		/* #ifdef MP-WEIXIN */
		display: flex;
		/* #endif */
		
		margin: 10px 0 0 34px;
		font-size: 14px;
		color: #666;
	}
	.order-status{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 20px 0 0 34px;
		font-size: 14px;
		color: #666;
		font-weight: bold;
	}
	
	.cancel_order{
		line-height: 26px;
		text-align: center;
		font-size: 14px;
		color: #144591;
		margin-right: 10px;
		width: 80px;
		height: 26px;
		background: #FFFFFF;
		border: 1px solid #144591;
		border-radius: 13px;
	}


</style>